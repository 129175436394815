@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Tiempos Fine';
  src: url('../client/fonts/TiemposFine.woff') format('woff'), url('../client/fonts/TiemposFine.woff2') format('woff2');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos Fine';
  src: url('../client/fonts/TiemposFineItalic.woff') format('woff'), url('../client/fonts/TiemposFineItalic.woff2') format('woff2');
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('../client/fonts/MaisonNeue-Book.woff') format('woff'), url('../client/fonts/MaisonNeue-Book.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Maison Neue';
  src: url('../client/fonts/MaisonNeue-Demi.woff2') format('woff2');
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}

body {
  @apply text-soot;
  @apply text-base;
  @apply bg-millet;
  font-family: 'Maison Neue', 'Helvetica', sans-serif;
}

.container {
  @apply px-5 md:px-10;
  max-width: 1440px;
}

a:not(.project-image, .title, .footer-icon, .home-icon) {
  position: relative;
  display: inline-flex;
  text-decoration: underline;
  text-underline-offset: 3px;
}

@media screen and (min-width: 768px) {
  a:not(.project-image, .title, .footer-icon, .home-icon) {
    text-decoration: none;
  }

  a:not(.project-image, .title, .footer-icon, .home-icon):after {
    @apply bg-berry;
    @apply w-full;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    transform: scaleX(0);
    opacity: 1;
    transform-origin: left;
    transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
    transform: scaleX(1);
    transform-origin: right;
    transition-delay: 0s;
  }

  a:not(.project-image, .title, .home-icon):hover:after {
    transform: scaleX(0);
    transform-origin: left;
  }
}

/* Loading Animation */
.dot-collision {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  @apply bg-berry;
}
.dot-collision::before, .dot-collision::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-collision::before {
  left: -10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  @apply bg-berry;
  animation: dot-collision-before 1.5s infinite ease-in;
}
.dot-collision::after {
  left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  @apply bg-berry;
  animation: dot-collision-after 1.5s infinite ease-in;
  animation-delay: 0.5s;
}

@keyframes dot-collision-before {
  0%, 50%, 75%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-15px);
  }
}
@keyframes dot-collision-after {
  0%, 50%, 75%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px);
  }
}

nav.nav-bar {
  backdrop-filter: blur(5px);
}
